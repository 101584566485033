import { jsx } from 'theme-ui';
import React, { Component } from 'react'
import "../styles/Banner.css";
import { Container, Box, Heading, Text,} from 'theme-ui';
// import BannerImg from '../assets/platform_devices.png';
// import BannerImg from '../assets/banner-thumb.png';
import ShapeLeft from '../assets/shape-left.png';
import ShapeRight from '../assets/shape-right.png';
import BannerTagline from "../assets/palmetto_cap_tagline.png";
import { Image } from "../components/Image";
import BannerImg from "../assets/skyline.jpg";

export class Banner extends Component {
  

  render() {
    return (
      <section className="css-1-Banner" id="home">
        <Image src={BannerTagline} className="banner-tagline"/>    
          <button className="banner-button">
            <a href="#contact">
            Contact Us
            </a>
          </button>
      </section>
    )
  }
}

export default Banner
