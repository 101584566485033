import { jsx, Image, Box, Heading, Text } from 'theme-ui';
import "../styles/FeatureCardColumn.css";

export default function FeatureCardColumn({
  src,
  altText = 'default alt text',
  title,
  text,
}) {
  return (
    <div className="css-1-featureCardColumn">
      <Image src={src} altText={altText} className="css-2-featureCardColumn"/>
      <div className="css-3-featureCardColumn">
        <h2 className="css-4-featureCardColumn">{title}</h2>
        <div className='css-5-featureCardColumn'>{text}</div>
      </div>
    </div>
  );
}