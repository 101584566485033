import { jsx } from 'theme-ui';
import React, { Component } from 'react'
import "../styles/BioBanner.css";
import { Container, Box, Heading, Text,} from 'theme-ui';
// import BannerImg from '../assets/platform_devices.png';
// import BannerImg from '../assets/banner-thumb.png';
import ShapeLeft from '../assets/shape-left.png';
import ShapeRight from '../assets/shape-right.png';
import BannerTagline from "../assets/palmetto_cap.png";
import { Image } from "../components/Image";
import BannerImg from "../assets/skyline.jpg";

export class BioBanner extends Component {
  

  render() {
    return (
      
        <div className='css-1-bio_banner'>
            <div className="css-2-bio_banner_info">            


            <div className="css-3-bio-info">
            <div className="css-4-bio_banner_image">
            </div>
            <div className="css-3-info">

            <div className="css-4-breadcrumb">
            <nav>
            <ol>
                <li>
                    <a>People</a>
                    <span>></span>
                </li>
                <li>
                    <span>Collin Cooper</span>
                </li>
                    </ol>
                </nav>

            </div>
            <h1 className="bio_name_text">Collin Cooper</h1>
            <p>Founder and Managing Partner</p>
            
            <div className="css-5-contact-info">
            <div>
            <a href="mailto:collin@palmetto.capital">                
            <span>E: </span>COLLIN@PALMETTO.CAPITAL
            </a>

            <a href="tel:214-934-4416">
            <span>P: </span>
            <span>214-934-4416</span>
            </a>
            </div>
            </div>
            </div>
        
            </div>
                        
            </div>
        </div>
      
    )
  }
}

export default BioBanner
