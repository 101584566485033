import React, { useState, useEffect } from "react";
import { jsx, Container, Flex, Button } from 'theme-ui';
import { keyframes } from '@emotion/react';
import IconButton from "@material-ui/core/IconButton";
import "../../styles/Header.css";
import logo from "../../assets/pcp.v1.4.png"
import { Link } from 'react-scroll';
import Logo from "../Logo";
import menuItems from "./header.data";
import { Image } from "../Image";
import MobileDrawer from "./MobileDrawer";

export default function Header({className}) {

    return (

        <header className={`header ${className}`} id="header">
            <div className="header-nav">
              <Logo                    
                src={logo}
                className="header__logo"
                />      
          
                <nav className="nav">
                {menuItems.map((menuItem, i) => (
                    <Link
                    activeClass="active"
                    to={menuItem.path}
                    href={menuItem.page}
                    spy={true}
                    offset={-70}
                    duration={500}
                    key={i}
                    >
                    {menuItem.label}
                    </Link>
                ))}
                </nav>                 
            
                <a href="/">
                <button className="header__button" aria-label="Sign in">
                    Investor Portal
                </button>
                </a>
            <MobileDrawer/>
            {/* </div> */}
            </div>
        </header>
    )
}


const positionAnim = keyframes`
  from {
    position: fixed;
    opacity: 1;
  }

  to {
    position: absolute;
    opacity: 1;
    transition: all 0.4s ease;
  }
`;

const styles = {
  header: {
    color: 'text',
    fontWeight: 'body',
    py: 4,
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'transparent',
    transition: 'all 0.4s ease',
    animation: `${positionAnim} 0.4s ease`,
    '.donate__btn': {
      flexShrink: 0,
      mr: [15, 20, null, null, 0],
      ml: ['auto', null, null, null, 0],
    },
    '&.sticky': {
      position: 'fixed',
      backgroundColor: 'background',
      color: '#000000',
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.06)',
      py: 3,
      'nev > a': {
        color: 'text',
      },
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  nav: {
    mx: 'auto',
    display: 'none',
    '@media screen and (min-width: 1024px)': {
      display: 'block',
    },
    a: {
      fontSize: 2,
      fontWeight: 'body',
      px: 5,
      cursor: 'pointer',
      lineHeight: '1.2',
      transition: 'all 0.15s',
      '&:hover': {
        color: 'primary',
      },
      '&.active': {
        color: 'primary',
      },
    },
  },
};
