import React from 'react';
import "../styles/About.css";
import { Container, Box, Heading, Text,} from 'theme-ui';
// import lym from "../../assets/lym-white.svg";
import SectionHeader from '../components/SectionHeader';

function BioAbout() {
    return (
        <section id="about">
        <div className="css-2-Banner">
        {/* <div className="css-3-Banner"> */}
        <div>
        
            <p>
            Collin Cooper is the Managing Partner and Founder of Palmetto Capital. He has 8-years of commercial real estate, private equity, and banking experience and is the former VP of Investor Relations for the Private Equity Firm Madera Residential. 
            </p>

            <h3>Professional Acheivements</h3>
            <p>- Receipient of Lubbock's "20 under 40" award</p>
            <p>- Named the "People's Choice" award by Leadership Lubbock</p>

            <h3>Philanthropic Affliations</h3>
            <p>- Vice President/Board Member, High Point Village</p>

            <h3>Personal Affliations</h3>
            <p>- Member at Park Cities Presbyterian Church</p>

            <h3>Education</h3>
            <p>- Collin earned a bachelor's degree in Finance from Texas A&M.</p>
        
        </div>
        </div>
      </section>  
    )
}

export default BioAbout
