import React from 'react';
import SectionHeader from '../components/SectionHeader';

// import Performance from '../assets/key-feature/performance.svg';
import Column from '../assets/column.svg';
import Partnership from '../assets/key-feature/partnership.svg';
import Subscription from '../assets/key-feature/subscription.svg';
import Support from '../assets/key-feature/support.svg';

import "../styles/CoreValues.css";
import FeatureCardColumn from '../components/FeatureCardColumn';

const data = [
    {
      id: 1,
      imgSrc: Column,
      altText: 'Integrity',
      title: 'Integrity',
      text:
        'We do the right thing.',
    },
    {
      id: 2,
      imgSrc: Column,
      altText: 'Respect',
      title: 'Respect',
      text:
        'We treat our counterparts with dignity.',
    },
    {
      id: 3,
      imgSrc: Column,
      altText: 'Teamwork',
      title: 'Teamwork',
      text:
        'We succeed because we collaborate.',
    },
    {
      id: 4,
      imgSrc: Column,
      altText: 'Excellence',
      title: 'Excellence',
      text:
        'We strive to be the best in class.',
    },
  ];

export default function CoreValues() {
    return (
        <section id="core-values" className="css-1-keyFeature">
        <div className="css-2-keyFeature">
            <SectionHeader
                slogan="Our values define who we are"
                title="Core Values"
                />
                <div className="css-3-keyFeature-grid">
                {data.map((item) => (
                    <FeatureCardColumn
                     key={item.id}
                      src={item.imgSrc}
                      alt={item.altText}
                      title={item.title}
                      text={item.text}
                    />
                ))}
                </div>
        </div>        
        </section>
    )
}