import React from 'react';
import { Box, Text, Heading } from 'theme-ui';

export default function SectionHeader({ title, slogan, isWhite, mBn }) {
  return (
    <div className='sectionHeader'>
      <p
      className="subTitle"
      style={{color: isWhite ? "white" : ''}}>
      {slogan}
      </p>
      <h2
      className="title"
      style={{color: isWhite ? "white" : ''}}>
      {title}
      </h2>
    </div>
  );
}
