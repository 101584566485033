import React from 'react';

import Layout from "../../src/Layout";
import BioBanner from '../sections/BioBanner';
import About from '../sections/About';
import Contact from "../sections/Contact";



export default function PeoplePage() {
    return (
    
        <Layout>
            <BioBanner/>
            <About/>
            <Contact/>
        </Layout>
    
    )
}