import React, { useRef } from 'react';
import "../styles/Newsletter.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReCAPTCHA from "react-google-recaptcha";
import lym from "../assets/lym-white.svg";
import { WmResError } from '../components/WmResErrors';
import * as actions from '../actions';

export class Newsletter extends React.Component {

    constructor() {
    super(); 

    this.state = {
        
        name: '',
        email: '',
        message: '',
        referred_by: '',
        phone_number: '',
        accredited: false,
        captchaValue: false,
        errors: []
    }
    this.handleName = this.handleName.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.subscribeUser = this.subscribeUser.bind(this);
    this.handleReferral = this.handleReferral.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.handleAccredited = this.handleAccredited.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    

    };

    handleName(event) {
        this.setState({name: event.target.value});
    }

    handleChange(event) {
        this.setState({email: event.target.value});
    }

    handleMessage(event) {
        this.setState({message: event.target.value});
    }

    handleReferral(event) {
        this.setState({referred_by: event.target.value});
    }


    handlePhone(event) {
        this.setState({phone_number: event.target.value});
    }


    handleAccredited(event) {
        this.setState({accredited: event.target.value});
    }


    handleSubmit(event) {
        event.preventDefault();

        this.subscribeUser({name: this.state.name, email: this.state.email, message: this.state.message, phone_number: this.state.phone_number, accredited: this.state.accredited, referred_by: this.state.referred_by});
        this.state = '';
    }

    resetData() {
        this.setState({name: '', last_name: '', email: '', message: '', phone_number: '', referred_by: '', accredited: false});
    }

    recaptchaChange(event) {
        this.setState({captchaValue: event.target.value})
    }

    
    

    subscribeUser(subscriberData) {
        actions.subscribeUser(subscriberData).then(
            (subscribeUser) => {
                
                this.resetData();
                toast.success('Someone from our team will reach out to you shortly!');
                
            },

            (errors) => {
                this.setState({errors})
        })
    }

    render() {

    const {errors} = this.state; 

    return (
        <center>
        <ToastContainer /> 
        <div className="newsletter">
              
        {/* <label>Contact Form</label> */}
        <p className="newsletter__text">Fill out this form to receive an email back from somebody at Palmetto Capital.</p>
        
        <form onSubmit={this.handleSubmit}>
        
        <input type="text" className="form__control" value={this.state.name} onChange={this.handleName} placeholder='Name' required/>
        
        <input type="text" className="form__control" value={this.state.email} onChange={this.handleChange} placeholder='Email' required/>
        <input type="tel" className="form__control" value={this.state.phone} onChange={this.handlePhone} placeholder='Phone Number' required />
        <input type="text" className="form__control" value={this.state.referred_by} onChange={this.handleReferral} placeholder='Referred By'/>
        {/* <label for="accredited"> Accredited? (Yes/No)</label>
        <input type="radio" name="accredited" className="form__control" value={this.state.accredited} onChange={this.handleAccredited} placeholder='Referred By'/> */}
        <input type="text" className="form__control" value={this.state.message} onChange={this.handleMessage} placeholder='Write a message...'/>
        {/* <p className="newsletter__text">By contacting us, you agree to the Terms of Service and Privacy Policy.</p> */}
        <center>
        {/* <ReCAPTCHA sitekey="6LfR1N8eAAAAABB55WoxB_21CEUuCeC7djeZJsc6" onChange={this.recaptchaChange}/> */}
        <button className="primary-button" type="submit">
        Contact Us
        </button>
        
        <WmResError errors={errors}/>
        </center>
        </form>
        </div>
        </center>
    )
}
}

export default Newsletter
