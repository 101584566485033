
import { Image } from 'theme-ui';
import { Link } from './Link.js';

export default function Logo({ src, alt, className, ...rest }) {
  return (
    <Link
      path="/"
      className={className}
      {...rest}
    >
    <Image src={src} alt={alt}/>
    </Link>
  );
}
