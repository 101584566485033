import thunk from 'redux-thunk';
import ReactGA from 'react-ga';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
// import { reducer as formReducer } from 'redux-form';

export const init = () => {

        ReactGA.initialize('G-K003KM6VR2');
        const gaTrackingMiddleware = store => next => action => {
        if (action.type === '@@router/LOCATION_CHANGE') {
        // const nextPage = `${action.payload.location.pathname}${action.payload.location.search}`;
        const nextPage = window.location.pathname;
        trackPage(nextPage);
    }
    return next(action);
    };

const trackPage = page => {
  ReactGA.pageview(page);
};

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(composeEnhancers(applyMiddleware(thunk, gaTrackingMiddleware))); 

    return store; 
}