import { NavLink as MenuLink, Link as A } from 'theme-ui';
import React from 'react'
import { IoIosArrowForward } from 'react-icons/io';


export function NavLink({ path, label, children, ...rest }) {
    return (
      <h1>NavLink</h1>
    );
  }

  export function Link({ path, label, children, ...rest }) {
    return (
      <A {...rest} href={path}>
        {children || label}
      </A>
    );
  }
  
export function LearnMore({path, label, children, ...rest }) {
  return (
    <A className="learn-more" {...rest}>
      {label ?? 'Learn More'} <IoIosArrowForward size="16px"/>
    </A>
  )
}