import React from 'react';
import {pretifyDate, toUpperCase} from '../helpers';

export function WmResError(props) {
    const errors = props.errors;

    return (
        errors.length > 0 && 
        <div className="alert alert-danger wm-res-errors">
        {console.log(errors)}
        {errors && !!errors.length && errors.map((error, index) => <p key={index}>{toUpperCase(error.detail)}</p>)}
        </div>
    )
}