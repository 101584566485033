import React from 'react';
import "../styles/Bio.css";
import Layout from "../../src/Layout";
import BioBanner from '../sections/BioBanner';
import BioAbout from '../sections/BioAbout';


export default function BioPage() {
    return (
    
        <Layout>
            <div className="bio">
                <BioBanner/>
                <BioAbout/>
            </div>
        </Layout>
    
    )
}