import { jsx, Box, Container, Image, Text } from 'theme-ui';
import React from 'react';
import { Link } from '../Link';
import FooterLogo from "../../assets/pcp.v1.4.png";
import data from './footer.data';
import icondata from "./icon.data";
import "../../styles/Footer.css";
// import lym from "../../assets/lym-white.svg";
import { FaFacebookF, FaTwitter, FaGithubAlt, FaDribbble, FaEnvelope, FaLinkedin } from 'react-icons/fa';

const social = [
  {
    path: 'mailto:info@palmetto.capital',
    icon: <FaEnvelope />,
  },
  {
    path: 'https://twitter.com/palmetto_cap',
    icon: <FaTwitter />,
  },
  {
    path: '/',
    icon: <FaLinkedin />,
  },
];

export default function Footer() {
    return (
        <footer className="css-1-footer">
            <div className="css-2-footer-topArea">
            <div className="css-3-footer-bottomArea">
            <Link path="#home">
                <Image src={FooterLogo} alt="Logo" style={{ maxWidth: "200px"}}/>            
            </Link>
            <div className="css-4-footer-menus">
            <nav>
              {data.menuItem.map((item, i) => (
                <Link 
                  path={item.path}
                  key={i}
                  label={item.label}
                  className="css-5-footer-links"
                />
              ))}
            </nav>
          </div>
          {/* {social.map((socialItem, i) => (
          <Box as="span" key={i} sx={styles.social.icon}>
          <a href={socialItem.path}>{socialItem.icon}</a>
          </Box>
        ))} */}
          {/* <a href="#home">Top of Page</a> */}
          <p className="css-6-footer-copyright">
          © Palmetto Capital {new Date().getFullYear()}
          </p>
        </div>
      </div>
    </footer>
    );
}

const styles = {
  handler: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: '0',
    width: '26px',

    '@media screen and (min-width: 1024px)': {
      display: 'none',
    },
  },

  drawer: {
    width: '100%',
    height: '100%',
    backgroundColor: 'dark',
  },

  close: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '25px',
    right: '30px',
    zIndex: '1',
    cursor: 'pointer',
  },

  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    pt: '100px',
    pb: '40px',
    px: '30px',
  },

  menu: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    a: {
      fontSize: '16px',
      fontWeight: '500',
      color: 'text_white',
      py: '15px',
      cursor: 'pointer',
      borderBottom: '1px solid #e8e5e5',
      transition: 'all 0.25s',
      '&:hover': {
        color: 'secondary',
      },
      '&.active': {
        color: 'secondary',
      },
    },
  },

  menuFooter: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    mt: 'auto',
  },

  social: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'text',
      fontSize: 14,
      mr: '15px',
      transition: 'all 0.25s',
      cursor: 'pointer',
      ':last-child': {
        mr: '0',
      },
      '&:hover': {
        color: 'secondary',
      },
    },
  },

  button: {
    color: 'white',
    fontSize: '14px',
    fw: '700',
    height: '45px',
    borderRadius: '5px',
    cursor: 'pointer',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    py: '0',
  },
};