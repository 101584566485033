import axios from 'axios';

import {
    SUBSCRIBE_USER,
    UNSUBSCRIBE_USER,
    GET_SUBSCRIBER
}
from './types';

export const subscribeUser = (email) => {
    return axios.post('/api/v1/subscribe', email).then(
        res => res.data,
        err => Promise.reject(err.response.data.errors)
    )
}

export const betaUser = (betaData) => {
    return axios.post('/api/v1/beta', betaData).then(
        res => res.data,
        err => Promise.reject(err.response.data.errors)
    )
}