import React from 'react';

import Layout from "../../src/Layout";
import Banner from '../sections/Banner';
import About from '../sections/About';
import CoreValues from '../sections/CoreValues';
import Contact from "../sections/Contact";

export default function IndexPage() {
    return (
    
        <Layout>
            <Banner/>
            <About/>
            <CoreValues/>
            <Contact/>
        </Layout>
    
    )
}