import titleize from 'titleize';
import * as moment from 'moment';

export const toUpperCase = value => value ? titleize(value) : ''

export const pretifyDate = date => moment(date).format('MMM Do')
export const pretifyDT = date => moment(date).format('MMM Do, YYYY')
export const pretifyDR = date => moment(date).format('M/D/YYYY')
export const pretifyYR = date => moment(date).format('YYYY')
export const pretifyVal = value => value.toFixed(2);
export const pretifyID = value => value.slice(value.length - 4)
export const pretifyID2 = value => value.slice(0, 4)