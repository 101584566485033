import React from 'react'
import './App.css';
import Layout from "./Layout";
import 'react-multi-carousel/lib/styles.css';
import 'react-modal-video/css/modal-video.min.css';
import 'rc-drawer/assets/index.css';
import 'typeface-dm-sans'
import { Provider } from 'react-redux';
import {BrowserRouter as Router, Routes, Route, Redirect, BrowserRouter} from "react-router-dom";
import IndexPage from './pages/Index';
import BioPage from './pages/Bio';
import PeoplePage from './pages/People';

const store = require('./reducers').init();

function App() {
  const [dimensions, setDimensions] = React.useState({ 
    height: window.innerHeight,
    width: window.innerWidth
  })

  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    
}

    window.addEventListener('resize', handleResize)
  })

  return (
    <BrowserRouter>
      <Provider store={store}>
        <div className="App">    
          <Routes>
              <Route path="/" element={<IndexPage/>}/>
              <Route path="/people" element={<PeoplePage/>}/>
              <Route path="/people/:name" element={<BioPage/>}/>
            </Routes>
        </div>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
