// Import Depedencies 
import React, { useState } from 'react';
import { jsx } from 'theme-ui';
import './App.css';
import Sticky from 'react-stickynode';
import Header from './components/header/Header';

import Footer from './components/footer/Footer';



export default function Layout({ blog, children }) {
  const [isSticky, setIsSticky] = useState(false);

  const handleStateChange = (status) => {
    if (status.status === Sticky.STATUS_FIXED) {
      setIsSticky(true);
    } else if (status.status === Sticky.STATUS_ORIGINAL) {
      setIsSticky(false);
    }
  };

  return (
    <React.Fragment>
      <Sticky innerZ={1001} top={0} onStateChange={handleStateChange}>
      
        <Header className={`${isSticky ? 'sticky' : 'unSticky'}`} />
      
      {/* <Header className={`${isSticky ? 'sticky' : 'unSticky'}`} /> */}
      </Sticky>
          <main id="content">
          {children}
          </main>
          <Footer/>      
        </React.Fragment>
        
  );
}
