export default [
    {
      path: 'home',
      label: 'Home',
    },
    {
      path: 'about',
      label: 'About',
    },

    {
        path: 'core-values',
        label: 'Values',
      },

      {
        path: 'contact',
        label: 'Contact',
      },

  ];