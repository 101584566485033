import React from 'react';
import "../styles/Contact.css";
import lym from "../assets/lym-white.svg";
import Newsletter from './Newsletter';
import SectionHeader from '../components/SectionHeader';

function Contact() {
    return (
        <section id="contact" className="contact">
        <SectionHeader
            slogan="Get in touch"
            title="Contact Us"
        />
        
        {/* <p>
        <a href="mailto:info@watermark.ventures" className='contact__email'>
        info@watermark.ventures
        
        </a>
        </p> */}
        <Newsletter/>
        </section>
    )
}

export default Contact
