import React from 'react';
import "../styles/About.css";
import { Container, Box, Heading, Text,} from 'theme-ui';
// import lym from "../../assets/lym-white.svg";
import SectionHeader from '../components/SectionHeader';

function About() {
    return (
        <section id="about">
            <div className="css-2-Banner">
                
                    <SectionHeader
                    slogan="An investment firm you can trust"
                    title="Corporate Mission"
                    />
                    <Text as="p" className="heroSecondary">
                    {/* <p> */}
                    Palmetto Capital (“Palmetto”) is a privately held real estate investment company founded in 2022 by Collin Cooper. Headquartered in Dallas, Texas, Palmetto serves as an investment vehicle for accredited investors in search of opportunistic returns in commercial real estate. Palmetto sources and curates opportunities provided by real estate operators to give investors access to the strongest, most disciplined options available in the market. 
                    </Text>
                    <Text as="p" className="heroSecondary">
                    Palmetto Capital leverages strong relationships with best-in-class sponsors and operators to deliver project excellence. Through an expansive network and flexible capital composition, Palmetto can provide unique investment opportunities across all tiers of the capital structure leading to outsized returns.
                    </Text>
                
            </div>
      </section>  
    )
}

export default About
